$main_color: #d7b369;
$main_bg:#000;
$bg_block: #242424;
$color_text: lighten(#b0b0b0, 15%);
$font-awesome: normal normal normal 14px/1 FontAwesome;
$second-color: #e23434;
$third-color: #8699CA; 
$height-button: 40px;
$width-form-order: 768px;
$border-radius-input: 10px;
$margin-grid: 15px;

@mixin wrapper_flex($display, $align-items, $justify-content) {
    display: $display;
    align-items: $align-items;
    justify-content: $justify-content;
    flex-wrap: wrap;
}

@mixin unicode-icon($unicode, $margin) {
    &::before {
        font: $font-awesome;
        content: $unicode;
        display: inline-block;
        margin-right: $margin;
        font-size: 24px;
    }
}

@mixin modal-window($width) {
    visibility: hidden;
    opacity: 0;
    width: $width;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 11;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s, visibility 0s 0.5s;
    border-radius: $border-radius-input;
}

@mixin input-style() {
    outline: 0;
    border: 0;
    height: $height-button;
    width: 100%;
    padding: 0 10px;
    border-radius: $border-radius-input;
    background: rgba(255, 255, 255, 0.8);
}

@mixin wrapper-select-style() {
    position: relative;
    i.fa {
        position: absolute;
        right: 5%;
        top: 30%;
        pointer-events: none;
        font-size: 18px;
        transition: 0.3s 
    }
    &:hover {
        i.fa {
            color: white;  
        }
    }

}

@mixin select-style() {
    outline: 0;
    border: 0;
    height: $height-button;
    width: 100%;
    padding: 0 10px;
    border-radius: $border-radius-input;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: rgba(255, 255, 255, 0.6);
}

@mixin button-style($bg-button, $width-button:100%, $color-button-text:#fff) {
    outline: 0;
    border: 0;
    background: $bg-button;
    height: $height-button;
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    width: $width-button;
    justify-content: center;
    border-radius: $border-radius-input;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: $color-button-text;
    transition: background 0.3s;
    &:hover {
        background: $third-color;
        background: darken($bg-button, 10%);
    }
}

@mixin row-style($margin:$margin-grid) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$margin;
    margin-right: -$margin;
}

@mixin col-size($size, $margin:$margin-grid) {
    margin-left: $margin;
    margin-right: $margin;
    width: calc(100% / 12 * #{$size} - #{$margin * 2});
}


.preloader {
	position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 9999;
    opacity: 1;
    visibility: visible;
    .loader {
        width: 200px;
        height: 200px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);    
    }
    
    &.done {
        transition: visibility 1s, opacity 1s;
        opacity: 0;
        visibility: hidden;
    }
}

.loader__image {
    animation: 1.5s spin infinite linear;
}

.loader__text {
    font-size: 28px;
    color: white;
}

@keyframes spin{
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
    100% {
        transform: rotate3d(0, 1, 0, 360deg);
    }
}

* {
    font-family: 'Roboto', sans-serif;
}

body {
    background: radial-gradient(ellipse at center, #7d7e7d 0, #0e0e0e 100%);
    background-repeat: no-repeat;
}

.wrapper {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}

a {
    text-decoration: none;
}


/*HEADER STYLES*/

.header {
    background: $main_bg;
    padding: 40px 0 0 0;
    @media only screen and (max-width: 768px) {
        padding: 20px 0 0 0;
    }
}

.header__wrapper {
    @include wrapper_flex(flex, center, space-between);
}

.header__logo-container,
.header__contacts {
    margin-bottom: 15px;
    font-size: 20px;
    @media only screen and (max-width: 768px) {
        margin: 0 auto 25px;
    }
}

.header__contacts {
    @media only screen and (max-width: 480px) {
        margin: 0 auto 0px;
    }
}

.header__logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.header__logo-caption {
    color: $main_color;
    font-size: 48px;
    text-transform: uppercase;
    font-weight: 700;
    @media only screen and (max-width: 480px) {
        font-size: 28px;
        text-align: center;
    }
}

.header__logo {
    margin-right: 10px;
    width: 120px;
}

.header__row {
    color: #fff;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.header__row_tel {
    @include unicode-icon('\f095', 24px);
}

.header__row_clock {
    @include unicode-icon('\f017', 24px);
}

.header__row_adress {
    @include unicode-icon('\f124', 24px);
}

.header__row_free {
    margin-top: 30px;
}

.header__tel-link {
    color: #fff;
    display: inline-block;
    margin: 20px 20px 20px 0;
}

.header__call-back {
    @include button-style($main_color, auto, $color-button-text:#fff);

    display: inline-flex;
    align-items: center;
}

.header__wrap {
    display: inline-block;
}

.header__free {
    @include button-style($second-color, auto, #fff);
    height: auto;
    padding: 10px 10px;
}


/*END HEADER STYLES*/

/*MAIN-CONTENT STYLES*/

.main-menu {
    @media only screen and (max-width: 768px) {
        display: none;
        position: absolute;
        width: calc(100% - 20px);
    }
}

.main-menu_active {
    @media only screen and (max-width: 768px) {
        display: block;
    }
}

.main-menu__ul {
    display: flex;
    font-size: 18px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        flex: 1 1;
    }
}

.main-menu__li {}

.wrapper_menu {
    padding: 20px 15px;
    @media only screen and (max-width: 768px) {
        text-align: center;
        position: relative;
        z-index: 10;
    }
    @media only screen and (max-width: 480px) {
        padding: 10px;
    }
    
}

.hamburger {
    display: none;
    @media only screen and (max-width: 768px) {
        display: inline-block;
        text-align: center;
    }
}

.hamburger-inner {
    background: $main_color;
    &::before {
        background: $main_color;
    }
    &::after {
        background: $main_color;
    }
}

.main-menu__link {
    display: block;
    padding: 15px 10px;
    background: $main_bg;
    color: white;
    text-decoration: none;
    transition: 0.3s;
    font-weight: bold;
    &:hover {
        background: $main_color;
        color: $main_bg;
    }
}

.main-menu__link_active {
    background: $main_color;
    color: $main_bg;
}

.main-content__abous-us{
    p, ul{
        margin-bottom: 20px;
        color: $color_text;
        font-size: 20px;
        line-height: 1.5;
        @media only screen and (max-width: 480px) {
            font-size: 18px;
        }
    }
    ul {
        padding-left: 25px;
        @media only screen and (max-width: 480px) {
            padding-left: 0px;
        }
    }
    li {
        padding-left: 35px;
        background: url('/img/gan.png') left center/20px no-repeat;
        margin-bottom: 10px;
    }
    h2 {
        margin-bottom: 20px;
        color: $color_text;
        font-size: 32px;
        line-height: 1.5;
        @media only screen and (max-width: 480px) {
            font-size: 28px;
        }
    }
}

.main-content__caption-block {
    padding: 10px;
//    background: $main_color;
    font-weight: bold;
    font-size: 28px;
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
    color: white;
}

.main-content__text-block {
    padding: 10px;
    margin-bottom: 10px;
    p, ul {
        margin-bottom: 20px;
        color: $color_text;
        font-size: 20px;
        line-height: 1.5;
        @media only screen and (max-width: 480px) {
            font-size: 18px;
        }
        
    }
    ul {
        padding-left: 25px;
        @media only screen and (max-width: 480px) {
            padding-left: 0px;
        }
    }
    li {
        padding-left: 35px;
        background: url('/img/gan.png') left center/20px no-repeat;
    }
}

// .main-content__paragraph {
//     margin-bottom: 20px;
//     color: $color_text;
//     font-size: 20px;
//     line-height: 1.5;
// }

/*SLIDER STYLES*/

.slider-wrapper {
    margin-bottom: 10px;
}

.main-slider__slide {
    height: 100vh;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    // @media only screen and (max-width: 768px) {
    //     height: 400px;
    // }
}

.slider-button {
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 50px;
    height: 50px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: $main_color;
    z-index: 1;
    border-radius: 50%;
    i {
        font-size: 24px;
        color: $main_bg;
    }
}

.slider-button_next {
    right: 20px;
}

.slider-button_prev {
    left: 20px;
}

.slider-dots {
    position: absolute;
    bottom: 20px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    z-index: 1;
    li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        border-radius: 50%;
        background: #fff;
        button {
            border: 0;
            outline: 0;
            font-size: 0;
            background: transparent;

        }
    }
    li.slick-active {
        background: $main_color;
    }
}

/* END SLIDER STYLES*/

/* FORM ORDER */

.order-section__wrapper {
    flex: 1 1;
    &_hidden {
        visibility: hidden;
    }
}

.order-section {
    background: url('/img/NRmFEsJC9TM.jpg') center center/cover no-repeat;
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    position: relative;
    @media only screen and (max-width: 768px) {
        height: auto;
        padding: 15px 0;
    }
}

.order-section__caption {
    color: white;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
}

.form-wrapper {
    display: flex;
    justify-content: center;
}

.form-order {
    max-width: $width-form-order;
    padding: 15px 0;
    flex: 1 1;
}

.form-order__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$margin-grid;
    margin-right: -$margin-grid;
    justify-content: center;
}

.form-order__col {
    margin-left: $margin-grid;
    margin-right: $margin-grid;
    margin-bottom: 15px;
    width: calc(100%/12*6 - #{$margin-grid * 2});
    @media only screen and (max-width: 768px) { 
        width: calc(100%/12*12 - #{$margin-grid * 2});
    }

}

.form-order__input {
    @include input-style();
}

.form-order__input_error {
    box-shadow:0 0 0 2px red;
}

.form-order__select-wr {
    @include wrapper-select-style();
}

.form-order__season {
    @include select-style();
}

.form-order__button {
    @include button-style($second-color);
    
}

/* FORM ORDER */

/*END MAIN-CONTENT STYLES*/

/*FOOTER STYLE*/

.footer {
    background: $main_bg;
    padding: 30px 0;
}

.footer__social-block {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.footer__social-item {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    text-decoration: none;
    margin: 0 5px;
    transition: 0.3s;
    i {
        margin: auto;
        font-size: 32px;
        color: #fff;
    }
    &_vk {
        background: #4a76a8;
        &:hover {
            background: darken(#4a76a8, 10%);
        }
    }
    &_inst {
        background: orange;
        &:hover{
            background: darken(orange, 10%);
        }
    }
}

.footer__copy {
    color: #fff;

}

/*END FOOTER STYLE*/

/* MAP */

.map-caption {
    padding: 10px;
    text-align: center;
    color: white;
    font-size: 32px;
    font-weight: 700;


}

.map {
    height: 500px;
    iframe {
        height: 100%;
        width: 100%;
    }
    @media only screen and (max-width: 768px) {
        height: 400px;
    }

}

/* MAP */

/* MODAL */

.overlay {
    visibility: hidden;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 10;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    transition: opacity 0.5s, visibility 0s 0.5s;
}

.overlay_visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s, visibility 0s 0.0s;
}

.modal {
    @include modal-window(500px);
    background: url('/img/bg2.jpg') center center/cover no-repeat;
}

.modal_call-back {
    box-shadow: 0 0 15px 0 #fff;
    @media only screen and (max-width: 500px) {
        width: 95%;
    }
}

.modal_free {
    box-shadow: 0 0 15px 0 #fff;
    @media only screen and (max-width: 500px) {
        width: 95%;
    }
}

.modal__row {
    margin-bottom: 10px;
}

.modal__close-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    @include unicode-icon('\f00d', 0);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    z-index: 2;
}

.modal__caption {
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    display: inline-block;
    background: rgba(0,0,0,0.5);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
}

.modal_visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s, visibility 0s 0.0s;
}

.modal__form-container {
    padding: 50px;
    text-align: center;
    position: relative;
    @media only screen and (max-width: 500px) {
        padding: 25px 15px;
    }
}

.modal__input {
    @include input-style();
}

.modal__button {
    @include button-style($second-color);
    transition: background 0.3s;
}

.modal__form {
    transition: 0s 0s;
    &_hidden {
        visibility: hidden;
    }
}

.modal__succes-text {
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    background: rgba(0,0,0,0.5);
}

.jGrowl {
    font-size: 20px !important;
    .jGrowl-notification {
        max-width: 350px !important;
    }
}

/* END MODAL */
.main-content__caption {
	font-size: 32px;
    color: white;
    margin: 25px 0;
    font-weight: bold;
    text-align: center;
}

.trainers {
    margin-bottom: 15px;
}

.trainers__row {
	@include row-style();
}

.trainers__relation {
    width: 100%;
    padding-bottom: 135%;
}

.trainers__col {
	@include col-size(4);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
    position: relative;
    transition: transform 0.3s;
    cursor: pointer;
    margin-bottom: 15px;
    
    @media only screen and (max-width: 768px) {
        @include col-size(6);
    }
    
    @media only screen and (max-width: 480px) {
        @include col-size(12);
    }

    @media only screen and (min-width: 769px) {
        &:hover {
            .trainers__button-wr{
                visibility: visible;
                opacity: 1;
                transition: opacity 0.3s, visibility 0s;
            }
            .trainers__button_more {
                left: 0;
                transition: left 0.2s 0.2s;
            }
            .trainers__button_enroll {
                right: 0;
                transition: right 0.2s 0.2s;
            }
        }
    }
}

.trainers__img-wr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.trainers__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
}

.trainers__button-wr {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    overflow: hidden;
    top: 0;
    left:0;
    transition: opacity 0.2s, visibility 0.2s 0.2s; 
    @media only screen and (max-width: 768px) {
        display: none;
    } 
}

.trainers__button {
    @include button-style($second-color, 90%);
    margin: 10px 0;
    position: relative;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.8);
    @media only screen and (max-width: 768px) {
        visibility: hidden;
    }    
    &_more {
        @include button-style($main_color, 90%);
        @media only screen and (min-width: 769px) {
            left: -100%;
            transition: left 0.2s 0s;
        }
        }
        
    &_enroll {
        @media only screen and (min-width: 769px) {
            right:-100%;
            transition: right 0.2s 0s;
        }
        
    }

    &_mobile {
        @media only screen and (min-width: 769px) {
            display: none;
        }

        @media only screen and (max-width: 768px) {
            display: flex;
            visibility: visible;
            width: calc(100%/12*12 - 10px);
            margin: 5px 5px;

        }
    }
}

.trainers__name {
    margin: 15px 0;
}

.trainers__button-wr-mob {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}

.trainers__description {
    position: absolute;
    bottom: 0;
    height: 100px;
    text-align: center;
    z-index: 20;
    width: 100%;
    font-size: 28px;
    color: white;
    font-weight: 700;
    z-index: 1;
    &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        z-index: 10;
        background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
        z-index: -1;
    }
    @media only screen and (max-width: 768px) {
        height: auto;
    }
}

.gallery-trainers {
    &__row{
        @include row-style();
    }
    &__col{
        @include col-size(3);
        position: relative;
        margin-bottom: 30px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        @media only screen and (max-width: 970px) {
            @include col-size(4);
        }
        @media only screen and (max-width: 768px) {
            @include col-size(6);
        }
        @media only screen and (max-width: 480px) {
            @include col-size(12);
        }
        
        a {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            height: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__relative {
        width: 100%;
        padding-bottom: 56.25%; 
    }
}

.trainers-info {
    @include row-style();
    &__icon {
        @include col-size(4);
        @media only screen and (max-width: 768px) {
            @include col-size(6);
        }
        @media only screen and (max-width: 480px) {
            @include col-size(12);
        }
    }
    &__description {
        @include col-size(8);
        @media only screen and (max-width: 768px) {
            @include col-size(6);
        }
        @media only screen and (max-width: 480px) {
            @include col-size(12);
        }
        p {
            color: $color_text;
            font-size: 20px;
            @media only screen and (max-width: 768px) {
                font-size: 18px;
            }
        }
    }
    &__image {
        margin-bottom: 20px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__relative {
        padding-bottom: 135%;
        width: 100%;
    }
    &__work-days {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        p {
            margin: 5px 10px 5px 0;
        }
    }

    &__work-day {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $second-color;
        margin: 0 5px;
        color: #fff;
    }

    &__work-days-wr {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 5px 0;
    }

    &__text {
        color: $color_text;
        font-size: 20px;

    }

    &__social {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__social-item {
        display: flex;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #4a76a8;
        margin: 0 5px;
        i {
            color: #fff;
            margin: auto;
            font-size: 32px;
        }
    }
    &__phone {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        a {
            font-size: 20px;
            display: flex;
            align-items: center;
            text-decoration: none;
            @include unicode-icon('\f095', 15px);
            color: #fff;
        }
    }
}

.subscription {
    @include row-style();
    margin-bottom: 25px;
    @media only screen and (max-width: 1200px) {
        justify-content: center;
    }
}

.subscription__col {
    @include col-size(4);
    height: 230px;
    margin-bottom: 30px;
    
    
    &:hover {
        .subscription__info {
            .subscription__cost {
                bottom: -100%;
                opacity: 0;      
            }
            .subscription__button {
                bottom: 0;
                visibility: visible;
            }
        }
    }
    @media only screen and (max-width: 1200px) {
        @include col-size(6);
        margin-bottom: 30px;
    }
    
    @media only screen and (max-width: 850px) {
        @include col-size(12);
        margin-bottom: 30px;
    }
    @media only screen and (max-width: 375px) {
        height: 216px;
    }
    @media only screen and (max-width: 320px) {
        height: 182px;
    }
}

.subscription__wr {
    position: relative;
    overflow: hidden;
    width: 369px;
    height: 100%;
    background: #ffee;
    margin: auto;
    background: url('/img/abon-back-white.png') center center/contain no-repeat;
    transition: box-shadow 0.3s;
    &:hover {
        box-shadow: 0 0 15px 0 rgba(255,255,255,1);
    }
    @media only screen and (max-width: 400px) {
        width: 100%;
    }
    
}

.subscription__img-container {
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: -95px;
}

.subscription__img {
    height: 95%;
}

.sub-table {
    width: 50%;
    position: absolute;
    right: 5%;
    top: 5%;

}

.subscription__info {
    width: 50%;
    right: 5%;
    position: absolute;
    bottom: 5%;
    @media only screen and (max-width: 400px) {
        bottom: 10%;
    }
}

.subscription__periond {
    font-size: 22px;
    font-weight: 700;
    font-style: italic;
    color: $main-color;
    
    @media only screen and (max-width: 400px) {
        font-size: 24px;
    }
}

.subscription__cost-wrapper{
    height: 30px;
    background: $main-color;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    margin-top: 10px;
}

.subscription__cost {
	font-size: 24px;
    font-weight: bold;
    font-style: italic;
    position: absolute;
    opacity: 1;
    bottom: 0;
    transition: bottom 0.3s, opacity 0.3s;
}

.subscription__button {
    visibility: hidden;
    position: absolute;
    bottom: -100%;
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: $second-color;
    transition: bottom 0.3s 0.3s, visibility 0.3s 0.3s, background 0.3s;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    &:hover {
        background: darken($second-color, 10%);
    }
}

.sub-table__row {
    @include row-style(2px);
    margin-bottom: 4px;

}

.sub-table__col {
    border: 1px solid black;
    height: 30px;
    border-radius: 5px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.sub-table__col_small {
    @include col-size(2, 2px);
}

.sub-table__col_big {
    @include col-size(10, 2px);
}

.main-content__row {
    @include row-style(15px);
}

.main-content__col {
    @include col-size(3, 15px);
    padding: 15px;
    background: #fff;
    box-shadow: 5px 5px 5px rgba(255,255,255,.3);
    border-radius: 5px;
    @media only screen and (max-width: 1000px) {
        @include col-size(6, 15px);
        margin-bottom: 15px;
    }
    @media only screen and (max-width: 550px) {
        @include col-size(12, 15px);
    }
}


.main-content__text {
    color: #000;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: bold;
    line-height: 1.4;
    span {
        color: $main_color;
        font-weight: bold;
    }
    
}

.additional-services {
    margin-bottom: 25px;
}

.additional-services__table {
    width: 100%;
}

.additional-services__row {
    th {
        background: #808080;
        height: 50px;
        vertical-align: middle;
        font-size: 28px;
        font-weight: 700;
        color: #fff
        
    }
    
}

.additional-services__col {
    height: 40px;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #808080;
    color: $color-text;
    font-size: 20px;
}

.additional-services__col_big {
    width: 70%;
    
}

.order-form-container {
    margin-bottom: 30px;
    position: relative;
    background: url(/img/order.jpg) center center/cover no-repeat;
}

.sub-form-detail_hidden {
    visibility: hidden;
    z-index: 0;
}

.order-form-container__bg {
    padding: 40px;
    min-height: 350px;
    @media only screen and (max-width: 1000px) {
        margin-bottom: 0;
        padding: 40px 15px;
    }
}

.order-form-container__caption {
    font-size: 28px;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 30px;
}

.order-form-container__row {
    @include row-style();
    margin-bottom: 25px;
    @media only screen and (max-width: 1000px) {
        margin-bottom: 0;
    }
}

.order-form-container__col {
    @include col-size(3);
    @media only screen and (max-width: 1000px) {
        @include col-size(6);
        margin-bottom: 15px;
    }
    @media only screen and (max-width: 600px) {
        @include col-size(12);
    }
}

.order-form-container__row_center {
    justify-content: center;
}

.order-form-container__input {
    @include input-style();
}

.order-form-container__button {
	@include button-style($second-color);
}

.order-form-container__select-wr {
    @include wrapper-select-style();
}

.order-form-container__select {
    @include select-style;
}

.map-contacts {
    height: 800px;
    @media only screen and (max-width: 480px) {
        height: 600px;
    }
}

.wrapper_relative {
    position: relative;
    z-index: 1;
}

.map-contacts__block {
    position: absolute;
    top: 100px;
    max-width: 400px;
    margin-right: 15px;
    
}

.map-contacts__header {
    background: $second-color;
    color: #fff;
    font-size: 28px;
    padding: 15px;
    text-align: center;
}

.map-contacts__body {
    background: #fff;
    padding: 15px;
}

.map-contacts__link {
    color: #000;
    font-size: 20px;
    @include unicode-icon('\f095', 20px);
    margin-bottom: 20px;
    display: block;
}

.map-contacts__text {
    color: #000;
    font-size: 20px;
    @include unicode-icon('\f124', 20px);
    &::before {
        display: block;
    }
    display: flex;
}